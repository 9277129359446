<template>
  <div style="width: 100%; position: relative">
    <div class="common-gaine">
      <div class="common-plate-title">
        <span>{{ $trans('认证实名信息', '认证实名信息') }}</span>
        <i
          class="iconfont icon-fanhui1"
          :title="$trans('返回上一页', '返回上一页')"
          @click="$router.history.go(-1)"
        ></i>
      </div>
      <div class="setting-input-box flex-bet-cen">
        <input
          type="text"
          :placeholder="$trans('请输入姓名', '请输入姓名')"
          v-model="name"
        />
      </div>
      <div class="setting-input-box flex-bet-cen">
        <input
          type="text"
          :placeholder="$trans('请输入证件号码', '请输入证件号码')"
          v-model="idNumber"
        />
      </div>
      <div class="authentication-tip">
        {{
          $trans(
            '(实名信息一经认证不可更改，如有疑问请联系上殊房客服)',
            '(实名信息一经认证不可更改，如有疑问请联系上殊房客服)'
          )
        }}
      </div>
      <div class="btn-fill" @click="submitHandle">
        {{ $trans('提交信息', '提交信息') }}
      </div>
    </div>
  </div>
</template>
<script>
import { authentication } from '@/util/js/api.js'
export default {
  name: '',
  data() {
    return {
      name: '',
      idNumber: '',
    }
  },
  methods: {
    submitHandle() {
      let nameReg = /^[\u4e00-\u9fa5]{2,4}$/
      let idReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (!nameReg.test(this.name) || !idReg.test(this.idNumber)) {
        this.$message.warning(
          this.$trans('请认真填写用户信息', '请认真填写用户信息')
        )
        this.name = ''
        this.idNumber = ''
        return
      }
      this.$confirm('一经操作不可修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let params = {
            idCard: this.idNumber,
            realName: this.name,
            userId: this.$store.state.userId,
          }
          authentication(params)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success(this.$trans('认证成功', '认证成功'))
                this.$router.history.push('/setting')
              } else {
                this.$message.error(
                  this.$trans('认证失败,请稍后重试', '认证失败,请稍后重试')
                )
                this.name = ''
                this.idNumber = ''
              }
            })
            .catch(() => {
              this.$message.error(
                this.$trans('网络错误，请稍后再试', '网络错误，请稍后再试')
              )
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消认证',
          })
        })
    },
  },
}
</script>
<style scoped>
.common-plate-title {
  margin-bottom: 100px;
}
.setting-input-box {
  height: 64px;
  border-bottom: 1px solid #dddddd;
  width: 100%;
  margin-bottom: 20px;
}
.setting-input-box input {
  flex: 1;
  outline: none;
  border: none;
  margin-right: 20px;
  background: transparent;
}
.btn-fill {
  cursor: pointer;
  width: 300px;
  margin: 63px auto 0;
}
.authentication-tip {
  display: block;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 45px;
  color: #2a9dcc;
  white-space: nowrap;
  font-family: 'myCoolFontMedium';
}
</style>
